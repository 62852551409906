<app-navbar></app-navbar>
<header class="masthead" id="page-top">
    <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
        <div class="d-flex justify-content-center">
            <div class="text-center">
                <h1 class="mx-auto my-0">BrainNut</h1>
                <h2 class="text-white-50 mx-auto mt-2 mb-5 text-shadow" style="color: white !important">
                    Cette plateforme dédiée à créer une expérience transparente et
                    collaborative entre les parents et la crèche pour le bien-être de vos
                    tout-petits.
                </h2>
                <a class="btn btn-primary" data-toggle="modal" data-target="#exampleModalArchiver" (click)="initRegisterForm()"
                    style="color: #fff">Démo</a>
            </div>
        </div>
    </div>
</header>

<!-- <section class="projects-section bg-light" id="about">
  <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5 justify-content-center">
          <div class="col-lg-8">
              <h2 class="text-white mb-4">Built with Bootstrap 5</h2>
              <p class="text-white-50">
                  Grayscale is a free Bootstrap theme created by Start Bootstrap. It can be yours right now, simply download the template on
                  <a href="https://startbootstrap.com/theme/grayscale/">the preview page.</a>
                  The theme is open source, and you can use it for any purpose, personal or commercial.
              </p>
          </div>
      </div>
      <img class="img-fluid" src="assets/img/ipad.png" alt="..." />
  </div>
</section> -->

<!-- <section class="projects-section bg-light" id="avantage">
    <div class="container px-4 px-lg-5">
        <div style="margin-bottom: 3rem;">
            <h1 class="text-center" style="font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        font-size: 3rem;
        line-height: 2.5rem;
        letter-spacing: 0.5rem;
        background: linear-gradient(rgba(103, 194, 156, 1), rgba(103, 194, 156, 0.5));
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
                background-clip: text;">Notre solution</h1>
        </div>
        <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <div class="col-lg-6 text-center">
                <div class="bg-green text-center h-100 project">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center text-lg-left">
                            <h4 class="text-white">Accès centralisé</h4>
                            <p class="mb-0 text-white-75">Accéder à toutes les informations concernant vos enfants à la
                                crèche. Cela simplifie la gestion des détails importants, tels que les événements, les
                                autorisations, les rapports de progrès, et les informations médicales.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="bg-coral text-center h-100 project">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center text-lg-left">
                            <h4 class="text-white">Suivi Personnalisé</h4>
                            <p class="mb-0 text-white-75"> Suivez le parcours de votre tout-petit au sein de la crèche
                                grâce à des mises à jour personnalisées sur les activités, les progrès et les moments
                                spéciaux.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gx-0 justify-content-center">
            <div class="col-lg-6 text-center">
                <div class="bg-green text-center h-100 project">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center text-lg-left">
                            <h4 class="text-white">Dossiers Numériques</h4>
                            <p class="mb-0 text-white-75">La possibilité d'accéder facilement aux documents importants,
                                tels que les rapports de progrès, les autorisations et les photos, simplifie la gestion
                                des informations importantes pour les parents et le personnel de la crèche.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 order-lg-first">
                <div class="bg-coral text-center h-100 project">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center text-lg-right">
                            <h4 class="text-white">Soutien du Développement de l'Enfant</h4>
                            <p class="mb-0 text-white-75"> En offrant un suivi détaillé des activités et des progrès de
                                l'enfant, la plateforme contribue au soutien optimal de son développement.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <div class="col-lg-6 text-center">
                <div class="bg-green text-center h-100 project">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center text-lg-left">
                            <h4 class="text-white">Calendrier Partagé</h4>
                            <p class="mb-0 text-white-75">La plateforme propose un calendrier partagé pour planifier et
                                suivre les événements, les vacances et les réunions de la crèche. Cela vous permet
                                d'être informé en temps réel des activités de la crèche.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="bg-coral text-center h-100 project">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center text-lg-left">
                            <h4 class="text-white">Sécurité et confidentialité</h4>
                            <p class="mb-0 text-white-75">La plateforme met l'accent sur la sécurité et la
                                confidentialité des données. Les informations personnelles des enfants et des parents
                                sont gérées de manière sécurisée.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="projects-section bg-light" id="avantage">
    <div class="container px-4 px-lg-5">
        <div style="margin-bottom: 3rem">
            <h1 class="text-center" style="
          font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI,
            Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji,
            Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
          font-size: 3rem;
          line-height: 2.5rem;
          letter-spacing: 0.5rem;
          background: linear-gradient(
            rgba(103, 194, 156, 1),
            rgba(103, 194, 156, 0.5)
          );
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
        ">
                Notre solution
            </h1>
        </div>
        <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <!-- Card 1 -->
            <div class="col-lg-4 text-center" style="cursor: pointer">
                <div class="bg-green text-center h-100 project" (click)="toggleCard(1)">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center text-lg-left">
                            <i class="fa-solid fa-universal-access fa-3x mb-2"></i>
                            <h4 class="text-white">Accès centralisé</h4>
                            <p *ngIf="card1Visible" class="mb-0 text-white-75">
                                Accéder à toutes les informations concernant vos enfants à la
                                crèche. Cela simplifie la gestion des détails importants, tels
                                que les événements, les autorisations, les rapports de progrès,
                                et les informations médicales.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Card 2 -->
            <div class="col-lg-4 text-center" style="cursor: pointer">
                <div class="bg-coral text-center h-100 project" (click)="toggleCard(2)">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center text-lg-left">
                            <i class="fa-solid fa-baby fa-3x mb-2"></i>
                            <h4 class="text-white">Suivi Personnalisé</h4>
                            <p *ngIf="card2Visible" class="mb-0 text-white-75">
                                Suivez le parcours de votre tout-petit au sein de la crèche
                                grâce à des mises à jour personnalisées sur les activités, les
                                progrès et les moments spéciaux.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Card 3 -->
            <div class="col-lg-4 text-center" style="cursor: pointer">
                <div class="bg-green text-center h-100 project" (click)="toggleCard(3)">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center text-lg-left">
                            <i class="fa-regular fa-folder fa-3x mb-2"></i>
                            <h4 class="text-white">Dossiers Numériques</h4>
                            <p *ngIf="card3Visible" class="mb-0 text-white-75">
                                La possibilité d'accéder facilement aux documents importants,
                                tels que les rapports de progrès, les autorisations et les
                                photos, simplifie la gestion des informations importantes pour
                                les parents et le personnel de la crèche.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <!-- Card 4 -->
            <div class="col-lg-4 text-center" style="cursor: pointer">
                <div class="bg-coral text-center h-100 project" (click)="toggleCard(4)">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center text-lg-left">
                            <i class="fa-solid fa-person-breastfeeding fa-3x mb-2"></i>
                            <h4 class="text-white">Soutien du Développement de l'Enfant</h4>
                            <p *ngIf="card4Visible" class="mb-0 text-white-75">
                                En offrant un suivi détaillé des activités et des progrès de
                                l'enfant, la plateforme contribue au soutien optimal de son
                                développement.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Card 5 -->
            <div class="col-lg-4 text-center" style="cursor: pointer">
                <div class="bg-green text-center h-100 project" (click)="toggleCard(5)">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center text-lg-left">
                            <i class="fa-regular fa-calendar-days fa-3x mb-2"></i>
                            <h4 class="text-white">Calendrier Partagé</h4>
                            <p *ngIf="card5Visible" class="mb-0 text-white-75">
                                La plateforme propose un calendrier partagé pour planifier et
                                suivre les événements, les vacances et les réunions de la
                                crèche. Cela vous permet d'être informé en temps réel des
                                activités de la crèche.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Card 6 -->
            <div class="col-lg-4 text-center" style="cursor: pointer">
                <div class="bg-coral text-center h-100 project" (click)="toggleCard(6)">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center text-lg-left">
                            <i class="fa-solid fa-shield-halved fa-3x mb-2"></i>
                            <h4 class="text-white">Sécurité et confidentialité</h4>
                            <p *ngIf="card6Visible" class="mb-0 text-white-75">
                                La plateforme met l'accent sur la sécurité et la confidentialité
                                des données. Les informations personnelles des enfants et des
                                parents sont gérées de manière sécurisée.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="projects-section bg-light" id="partner">
    <div class="container px-4 px-lg-5">
        <div style="margin-bottom: 3rem">
            <h1 class="text-center" style="
          font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI,
            Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji,
            Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
          font-size: 3rem;
          line-height: 2.5rem;
          letter-spacing: 0.5rem;
          background: linear-gradient(
            rgba(103, 194, 156, 1),
            rgba(103, 194, 156, 0.5)
          );
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
        ">
                Nos partenaires
            </h1>
        </div>
        <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <!-- Utilisation du carrousel pour les petits écrans -->
            <div class="col-12 d-md-none">
                <div id="logoCarousel" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner text-center">
                        <div class="carousel-item active">
                            <img src="../../../assets/img/activité.png" alt="Partner Logo 1" class="img-fluid logo"
                                onmouseover="zoomIn(event)" onmouseout="zoomOut(event)" />
                        </div>
                        <div class="carousel-item">
                            <img src="../../../assets/img/parent.png" alt="Partner Logo 2" class="img-fluid logo"
                                onmouseover="zoomIn(event)" onmouseout="zoomOut(event)" />
                        </div>
                        <!-- Ajoutez les autres logos ici -->
                    </div>
                    <a class="carousel-control-prev" href="#logoCarousel" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#logoCarousel" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>

            <!-- Affichage en ligne pour les écrans plus grands -->
            <div class="col-12 d-none d-md-flex justify-content-center align-items-center flex-wrap">
                <img src="../../../assets/img/activité.png" alt="Partner Logo 1" class="img-fluid logo"
                    onmouseover="zoomIn(event)" onmouseout="zoomOut(event)" />
                <img src="../../../assets/img/parent.png" alt="Partner Logo 2" class="img-fluid logo"
                    onmouseover="zoomIn(event)" onmouseout="zoomOut(event)" />
                <img src="../../../assets/img/temps.png" alt="Partner Logo 3" class="img-fluid logo"
                    onmouseover="zoomIn(event)" onmouseout="zoomOut(event)" />
                <img src="../../../assets/img/activité.png" alt="Partner Logo 4" class="img-fluid logo"
                    onmouseover="zoomIn(event)" onmouseout="zoomOut(event)" />
                <img src="../../../assets/img/parent.png" alt="Partner Logo 5" class="img-fluid logo"
                    onmouseover="zoomIn(event)" onmouseout="zoomOut(event)" />
            </div>
        </div>
    </div>
</section>

<section class="projects-section bg-light" id="avis">
    <div class="container px-4 px-lg-5">
        <div style="margin-bottom: 3rem">
            <h1 class="text-center" style="
          font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI,
            Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji,
            Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
          font-size: 3rem;
          line-height: 2.5rem;
          letter-spacing: 0.5rem;
          background: linear-gradient(
            rgba(103, 194, 156, 1),
            rgba(103, 194, 156, 0.5)
          );
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
        ">
                Avis
            </h1>
        </div>
        <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <!-- Example card -->
            <div class="card" style="
          border: none;
          border-bottom: 0.2rem solid #76c8a6;
          box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03),
            0 4px 16px rgba(34, 34, 34, 0.1);
        ">
                <div class="row">
                    <div class="rounded-circle col-3" style="width: 135px; height: 105px">
                        <img src="https://bambinosit.com/app/public/uploads/20231003192919191919_avis.jpeg"
                            class="card-img-top img-fluid" alt="Photo du parent" />
                    </div>
                    <div class="col-3 mt-5">
                        <h5 class="card-title mb-0" style="color: #76c8a6">
                            <strong>Martines Julie</strong>
                        </h5>
                    </div>
                </div>
                <div class="row col-10 m-auto text-center">
                    <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad nam
                        omnis, deserunt accusantium placeat quia voluptas quo optio autem
                        hic sequi facilis similique doloribus inventore porro reprehenderit
                        illo nesciunt labore.
                    </p>
                </div>

                <div class="card-footer bg-transparent border-top-0 text-right">
                    <small class="text-muted">Publié le 01/01/2023</small>
                </div>
            </div>
        </div>

        <br />
        <br />
        <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <!-- Example card -->
            <div class="card" style="
          border: none;
          border-bottom: 0.2rem solid coral;
          box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03),
            0 4px 16px rgba(34, 34, 34, 0.1);
        ">
                <div class="row">
                    <div class="rounded-circle col-3" style="width: 135px; height: 105px">
                        <img src="https://bambinosit.com/app/public/uploads/20231004191640404040_avis.jpeg"
                            class="card-img-top img-fluid" alt="Photo du parent" />
                    </div>
                    <div class="col-3 mt-5">
                        <h5 class="card-title mb-0" style="color: coral">
                            <strong>Martines Julie</strong>
                        </h5>
                    </div>
                </div>
                <div class="row col-10 m-auto text-center">
                    <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ad nam
                        omnis, deserunt accusantium placeat quia voluptas quo optio autem
                        hic sequi facilis similique doloribus inventore porro reprehenderit
                        illo nesciunt labore.
                    </p>
                </div>

                <div class="card-footer bg-transparent border-top-0 text-right">
                    <small class="text-muted">Publié le 01/01/2023</small>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="projects-section bg-light" id="contact">
    <div class="container px-4 px-lg-5" style="padding: 3rem; background-color: #f0f0f0;border-bottom: 0.2rem solid #76c8a6;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03),
    0 4px 16px rgba(34, 34, 34, 0.1);">
        <div style="margin-bottom: 3rem;">
            <h1 class="text-center" style="font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
          font-size: 3rem;
          line-height: 2.5rem;
          letter-spacing: 0.5rem;
          background: linear-gradient(rgba(103, 194, 156, 1), rgba(103, 194, 156, 0.5));
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
                  background-clip: text;">Contactez nous</h1>
        </div>
        <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
            <form #f0="ngForm" [formGroup]="registerForm2">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="nom"><strong style="color: #76c8a6; font-size: 1.2rem;">Nom <b style="color: red">*</b></strong></label>
                        <input type="text" class="form-control" id="nom" placeholder="Nom" formControlName="nom"
                            [ngClass]="{ 'is-invalid': submitted2 && f2.nom.errors }" />
                        <div *ngIf="submitted2 && f2.nom.errors" class="invalid-feedback">
                            <div *ngIf="f2.nom.errors.required">
                                Veuillez renseigner ce champ.
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="prenom"><strong style="color: #76c8a6; font-size: 1.2rem;">Prénom <b style="color: red">*</b></strong></label>
                        <input type="text" class="form-control" id="prenom" placeholder="Prénom"
                            formControlName="prenom" [ngClass]="{ 'is-invalid': submitted2 && f2.prenom.errors }" />
                        <div *ngIf="submitted2 && f2.prenom.errors" class="invalid-feedback">
                            <div *ngIf="f2.prenom.errors.required">
                                Veuillez renseigner ce champ.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="email"><strong style="color: #76c8a6; font-size: 1.2rem;">Email <b style="color: red">*</b></strong></label>
                    <input type="email" class="form-control" id="email" placeholder="E-mail" formControlName="email"
                        [ngClass]="{ 'is-invalid': submitted2 && f2.email.errors }" />
                    <div *ngIf="submitted2 && f2.email.errors" class="invalid-feedback">
                        <div *ngIf="f2.email.errors.required">
                            Veuillez renseigner ce champ.
                        </div>
                        <div *ngIf="f2.email.errors.email">
                            Merci de saisir une adresse email valide.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="telephone"><strong style="color: #76c8a6; font-size: 1.2rem;">Numéro de téléphone <b style="color: red">*</b></strong></label>
                    <br />
                    <ngx-intl-tel-input [cssClass]="'form-control input-custom-phone'" formControlName="telephone"
                        [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.Belgium"
                    [maxLength]="15"
                    [phoneValidation]="true"
                    name="telephone"
                    [ngClass]="{ 'is-invalid': submitted2 && f2.telephone.errors }" 
                  ></ngx-intl-tel-input>
                  <div
                    *ngIf="submitted2 && f2.telephone.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f2.telephone.errors.required">Champ obligatoire.</div>
                  </div>
                  <div
                    *ngIf="
                      registerForm2.value.telephone &&
                      !f0.form.controls['telephone'].invalid == false
                    "
                    style="color: red"
                  >
                    Numéro de téléphone valide <i class="bi bi-x"></i>
                  </div>
                  <div
                    *ngIf="
                      registerForm2.value.telephone &&
                      !f0.form.controls['telephone'].invalid == true
                    " style="color: green">
                        Numéro de téléphone valide <i class="bi bi-check"></i>
                    </div>
                </div>
                <div class="form-group">
                    <label for="message"><strong style="color: #76c8a6; font-size: 1.2rem;">Message <b style="color: red">*</b></strong></label>
                    <textarea class="form-control" id="message" rows="3" placeholder="Votre message"
                        formControlName="message" [ngClass]="{ 'is-invalid': submitted2 && f2.message.errors }">
                  </textarea>
                    <div *ngIf="submitted2 && f2.message.errors" class="invalid-feedback">
                        <div *ngIf="f2.message.errors.required">
                            Veuillez renseigner ce champ.
                        </div>
                        <div *ngIf="f2.message.errors.maxlength">
                            Ce champ peut comporter au maximum 200 caractères.
                        </div>
                    </div>
                </div>
                <div class="text-center mt-4">
                    <button class="btn btn-add" (click)="onSubmit2()">Envoyer</button>
                </div>
            </form>
        </div>

    </div>
</section>

<app-footer></app-footer>

<!--modal-->
<div class="modal fade" id="exampleModalArchiver" #exampleModalArchiver tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel" style="color: #76c8a6">
                    Demandez un démo
                </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #f00="ngForm" [formGroup]="registerForm">
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="nom">Nom <b style="color: red">*</b></label>
                            <input type="text" class="form-control" id="nom" placeholder="Nom" formControlName="nom"
                                [ngClass]="{ 'is-invalid': submitted && f.nom.errors }" />
                            <div *ngIf="submitted && f.nom.errors" class="invalid-feedback">
                                <div *ngIf="f.nom.errors.required">
                                    Veuillez renseigner ce champ.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="prenom">Prénom <b style="color: red">*</b></label>
                            <input type="text" class="form-control" id="prenom" placeholder="Prénom"
                                formControlName="prenom" [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }" />
                            <div *ngIf="submitted && f.prenom.errors" class="invalid-feedback">
                                <div *ngIf="f.prenom.errors.required">
                                    Veuillez renseigner ce champ.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email_etablissement">Nom de votre établissement <b style="color: red">*</b></label>
                        <input type="text" class="form-control" id="email_etablissement"
                            placeholder="Nom de votre établissement" formControlName="nomEtablissement" [ngClass]="{
                'is-invalid': submitted && f.nomEtablissement.errors
              }" />
                        <div *ngIf="submitted && f.nomEtablissement.errors" class="invalid-feedback">
                            <div *ngIf="f.nomEtablissement.errors.required">
                                Veuillez renseigner ce champ.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email">Email <b style="color: red">*</b></label>
                        <input type="email" class="form-control" id="email" placeholder="E-mail" formControlName="email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">
                                Veuillez renseigner ce champ.
                            </div>
                            <div *ngIf="f.email.errors.email">
                                Merci de saisir une adresse email valide.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="telephone">Numéro de téléphone <b style="color: red">*</b></label>
                        <br />
                        <ngx-intl-tel-input [cssClass]="'form-control input-custom-phone'" formControlName="telephone"
                            [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Belgium" [maxLength]="15"
                            [phoneValidation]="true" name="telephone"
                            [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }"></ngx-intl-tel-input>
                        <div *ngIf="submitted && f.telephone.errors" class="invalid-feedback">
                            <div *ngIf="f.telephone.errors.required">Champ obligatoire.</div>
                        </div>

                        <div *ngIf="
                registerForm.value.telephone &&
                !f00.form.controls['telephone'].invalid == false
              " style="color: red">
                            Numéro de téléphone valide <i class="bi bi-x"></i>
                        </div>
                        <div *ngIf="
                registerForm.value.telephone &&
                !f00.form.controls['telephone'].invalid == true
              " style="color: green">
                            Numéro de téléphone valide <i class="bi bi-check"></i>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="message">Votre demande <b style="color: red">*</b></label>
                        <textarea class="form-control" id="message" rows="3" placeholder="Votre demande"
                            formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
            </textarea>
                        <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                            <div *ngIf="f.message.errors.required">
                                Veuillez renseigner ce champ.
                            </div>
                            <div *ngIf="f.message.errors.maxlength">
                                Ce champ peut comporter au maximum 200 caractères.
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer text-center">
                <button class="btn btn-add" (click)="onSubmit()">Envoyer</button>
            </div>
        </div>
    </div>
</div>