import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ContactMarketingService } from 'src/app/services/contact-marketing.service';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  card1Visible = false;
  card2Visible = false;
  card3Visible = false;
  card4Visible = false;
  card5Visible = false;
  card6Visible = false;
  card1TitleVisible = true;
  card2TitleVisible = true;
  card3TitleVisible = true;
  card4TitleVisible = true;
  card5TitleVisible = true;
  card6TitleVisible = true;

  country='BE'
  countryCode="32";
  separateDialCode = true ;
	SearchCountryField = SearchCountryField;
	TooltipLabel ;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  
  @ViewChild('exampleModalArchiver') modal: any;
  registerForm: FormGroup;
  registerForm2 :FormGroup
  submitted = false;
  submitted2: boolean;
  constructor(
    private meta:Meta,
    private title: Title ,
    private formBuilder: FormBuilder,
    private contactService :ContactMarketingService

  )
  {
    this.meta.updateTag(
      {name:"description", content:"Découvrez notre plateforme de e-learning interactive qui vous offre un accès à une multitude de cours en ligne. Apprenez à votre rythme, acquérez de nouvelles compétences et développez votre potentiel avec notre plateforme d'apprentissage en ligne."}
      
    )
    this.setTitle("BrainNut"

    )
  }
  setTitle(newtille){
    this.title.setTitle(newtille)
  }


  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
          
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      nomEtablissement: ['', Validators.required],
      telephone: ['', Validators.required],
      message: ['', [Validators.required, Validators.maxLength(200)]],

      
  }, {
      //validator: MustMatch('password', 'confirmPassword')
  });
  this.registerForm2 = this.formBuilder.group({
          
    nom: ['', Validators.required],
    prenom: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    telephone: ['', Validators.required],
    message: ['', [Validators.required, Validators.maxLength(200)]],

    
}, {
    //validator: MustMatch('password', 'confirmPassword')
});
  }
  get f() { return this.registerForm.controls; }
  get f2() { return this.registerForm2.controls; }

  onSubmit() {
    
    this.submitted = true;
    if (this.registerForm.invalid) {
        return;
    }
    this.registerForm.value.telephone = this.registerForm.value.telephone.internationalNumber

    this.contactService.createConatct(this.registerForm.value).subscribe(
      (data) => {
        if (this.modal) {
          this.modal.nativeElement.querySelector('.close').click();
        }
        this.onReset()

      
      },
     
    );
}
onReset() {
  this.submitted = false;
  this.registerForm.reset();
}
zoomIn(event: any): void {
  event.target.style.transform = 'scale(1.2)';
}

zoomOut(event: any): void {
  event.target.style.transform = 'scale(1)';
}
toggleCard(cardNumber: number) {
  switch (cardNumber) {
      case 1:
          this.card1Visible = !this.card1Visible;
          break;
      case 2:
          this.card2Visible = !this.card2Visible;
          break;
      case 3:
          this.card3Visible = !this.card3Visible;
          break;
      case 4:
          this.card4Visible = !this.card4Visible;
          break;
      case 5:
          this.card5Visible = !this.card5Visible;
          break;
      case 6:
          this.card6Visible = !this.card6Visible;
          break;
  }
}

onSubmit2() {
  
  this.submitted2 = true;
  if (this.registerForm2.invalid) {
      return;
  }
  this.registerForm2.value.telephone = this.registerForm2.value.telephone.internationalNumber

  this.contactService.createConatctSimple(this.registerForm2.value).subscribe(
    (data) => {
      // if (this.modal) {
      //   this.modal.nativeElement.querySelector('.close').click();
      // }
      this.onReset2()

    
    },
   
  );
}
onReset2() {
this.submitted2 = false;
this.registerForm2.reset();
}

initRegisterForm(){
  this.onReset()
}
}
