<section class="contact-section bg-black">
    <div class="container px-4 px-lg-5">
        <div class="row gx-4 gx-lg-5">
            <div class="col-md-4 mb-3 mb-md-0">
                <div class="card py-4 h-100">
                    <div class="card-body text-center">
                        <i class="fas fa-map-marked-alt text-primary mb-2" style="color: #76c8a6 !important;"></i>
                        <h4 class="text-uppercase m-0">Address</h4>
                        <hr class="my-4 mx-auto" />
                        <div class="small text-black-50">4923 Market Street, Orlando FL</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-3 mb-md-0">
                <div class="card py-4 h-100">
                    <div class="card-body text-center">
                        <i class="fas fa-envelope text-primary mb-2" style="color: #76c8a6 !important;"></i>
                        <h4 class="text-uppercase m-0">Email</h4>
                        <hr class="my-4 mx-auto" />
                        <div class="small text-black-50"><a href="#!">hello@yourdomain.com</a></div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-3 mb-md-0">
                <div class="card py-4 h-100">
                    <div class="card-body text-center">
                        <i class="fas fa-mobile-alt text-primary mb-2" style="color: #76c8a6 !important;"></i>
                        <h4 class="text-uppercase m-0">Phone</h4>
                        <hr class="my-4 mx-auto" />
                        <div class="small text-black-50">+1 (555) 902-8832</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="social d-flex justify-content-center">
            <a  class="mx-2"><i class="fab fa-facebook-f"></i></a>
            <a class="mx-2" ><i class="fab fa-instagram"></i></a>
            <a class="mx-2" ><i class="fab fa-linkedin"></i></a>
        </div>
        <br>
    </div>
</section>
<!-- Footer-->
<footer class="footer  small text-center text-white-50"><div class="container px-4 px-lg-5" style="color: white;">&copy;  Tous droits réservés 2024 - Conçu et développé par 
    <a href="https://www.voguelconsulting.com/" target="_blank" style="cursor: pointer; color: #fff;text-decoration: none;">Voguel consulting</a> </div></footer>