<nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
  <div class="container px-4 px-lg-5">
      <a class="navbar-brand" href="#page-top" style="color: coral !important; font-size: 1.75rem;"><span style="color:#76c8a6 ;font-size: 1.75rem;">Brain</span>Nut</a>
      <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          Menu
          <i class="fas fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ms-auto">
              <li class="nav-item"><a class="nav-link" href="#page-top">Accueil</a></li>
              <li class="nav-item"><a class="nav-link" href="#avantage">Notre solution</a></li>
              <li class="nav-item"><a class="nav-link" href="#partner">Nos partenaires</a></li>
              <li class="nav-item"><a class="nav-link" href="#avis">Avis</a></li>
              <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
              <li class="nav-item"><a class="nav-link" href="">FAQ</a></li>
          </ul>
          <ul class="navbar-nav ms-auto">
            <li class="nav-item" >
                <a href="{{urlBackOffice}}"  class="btn-connexion" target="_blank">Se connecter</a>
            </li>
          </ul>
      </div>
  </div>
</nav>