// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //baseUrl:  `http://localhost:3007/api`,
  baseUrl:  `/api`,
  recaptcha: {
    //siteKey: '6Lf7UL0cAAAAAIt_m-d24WG4mA1XFPHE8yVckc5S',
    siteKey: '6LeSzMYhAAAAAJmk9AD9o3z2EeK0NMbHuNUM8klK',
  },
  //urlBackoffice : " http://localhost:4200/"
  urlBackoffice : " https://brainut-bo.voguelconsulting.com/"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
